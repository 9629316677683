.mechanics {
  .mechanic {
    margin: 2rem;
    .title-row {
      font-size: 1.23rem;
      font-weight: 700;
    }
    .examples {
      .learn-more {
        margin: .25rem;
      }
      .cards {
        margin-bottom: 0;
      }
    }
  }
}
