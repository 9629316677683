.card-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translate(-50%, -50%);
    padding: 1rem;
    border-radius: 1rem;
    .display-card {
        height: 30rem;
        border-radius: 1rem;
        margin: 2rem;
    }
    .info {
        display: flex;
        flex-flow: column;
        .info-text {
            text-align: center;
            color: #99ccad;
            background-color: #4f5250;
            border-color: #4f5250;
        }
    }
}

.modal-content {
    border: 0;
}

.modal.fade .modal-dialog {
    transition: none;
    transform: none;
}