.top-picks-content {
    margin: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
}

.row {
    justify-content: center;
    margin-bottom: 1rem;
}

.spinner-border {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 4rem;
    width: 4rem;
    margin: -2rem 0 0 -2rem;
    border: .5rem solid currentColor;
    border-right-color: transparent;
    z-index: 99;
}

.scroll-button {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
    padding-right: 1.5rem;
    padding-left: 1.5rem; 
}