.donate {
  position: fixed;
  bottom: 0;
  right: 0;
}

@media only screen and (min-width: 500px) {
  .donate {
    height: 10rem;
  }
}

@media only screen and (max-width: 500px) {
  .donate {
    height: 6rem;
  }
}