.nav-div {
  display: table;
  margin: 1rem;
  width: 100%;
}

@media only screen and (min-width: 500px) {
  .btn-toolbar {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 500px) {
  .btn-toolbar {
    justify-content: space-evenly;
  }
}