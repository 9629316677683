h1 {
    float: left;
}

h2 {
    text-align: center;
}

.top-picks-header {
    margin: auto 1rem;
    .navbar-brand {
        font-weight: 700;
    }
}

.dropdown-toggle {
    cursor: pointer;
}

.icon {
    width: 1.75rem;
    margin-right: .5rem;
}
